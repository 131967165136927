import * as Sentry from '@sentry/sveltekit';
Sentry.init({
	dsn: 'https://a443170eeabe22f99dd52db4214d078e@o4507613421043712.ingest.us.sentry.io/4507613422878720',

	tracesSampleRate: 1.0,

	// Optional: Initialize Session Replay:
	integrations: [Sentry.replayIntegration()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});

const myErrorHandler = ({ error, event }) => {
	console.error('error');
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
